import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';
import { Cycle } from '../../services/cycle';
import { Issue, getPoints, isDone } from '../../services/issue';
import IssueComponent from './Issue';
import SprintIssues from './SprintIssues';
import SprintEpicSummary from './SprintEpicSummary';
import moment from 'moment';
import { Sprint, getSprintIssues, getSprintInfo } from '../../services/sprint';
import { Epic } from '../../services/epic';

const styles = createStyles({
  column: {
    width: 200,
    textAlign: 'center',
    flexShrink: 0,
  },
  goalTitle: {
    width: 300,
    flexShrink: 0,
  },
  goalPoints: {
    color: '#999',
    fontSize: '0.8em',
  },
  removedPoints: {
    color: '#AAA',
  },
  ticketsContainer: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    fontSize: '0.9em',
  },
  sprintContainer: {
    width: 400,
    flexShrink: 0,
    display: 'flex',
  }
});

type EnrichedIssue = Issue & {
  epicKey: string[];
  isVersionned: boolean[];
};

interface EpicStatsProps extends WithStyles<typeof styles> {
  epicStats: { pct: number; points: number; donePoints: number };
}

const EpicStat = withStyles(styles)(({ epicStats, classes }: EpicStatsProps) => {
  return (
    <Tooltip title={`Seuls les tickets sur HA sont comptabilisés pour l'avancement de l'epic`} arrow>
      <Typography className={classes.column}>
        {epicStats.pct}% <span className={classes.goalPoints}>({epicStats.donePoints}/{epicStats.points}pts)</span>
      </Typography>
    </Tooltip>
  );
});

interface Props extends WithStyles<typeof styles> {
  cycle: Cycle;
  epic: Epic;
  expanded: boolean;
  onChange: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  boardIssues: Issue[];
  sprints: Sprint[];
};

function EpicRow(props: Props) {

  const { classes, expanded, onChange, epic, cycle, boardIssues, sprints } = props;

  const cycleStartDate = moment(cycle.startDate).endOf('day');

  const issuesEpic = Array(sprints.length + 1).fill(0).map((_, index) => {
    return boardIssues
      .filter(i => (i as EnrichedIssue).epicKey[index] === epic.key && (i as EnrichedIssue).isVersionned[index])
      .map(issue => {
        const date = index === 0 ? cycleStartDate : sprints[index - 1].endDate;
        const points = issue ? getPoints(issue, cycleStartDate) : 0;

        const childrenDonePoints = issue.children.reduce((sum, child) => {
          return sum + (isDone(child, date, true) ? getPoints(child, cycleStartDate) : 0);
        }, 0);
        const donePoints = isDone(issue, date, true) ? points : childrenDonePoints;

        return {
          label: issue.key,
          issue,
          deleted: false,
          points: points || 0,
          donePoints,
        };
      });
  });

  const sprintsInfo = sprints.map((sprint, index) => {
    const epicIssues = boardIssues.filter(i => (i as EnrichedIssue).epicKey[index + 1] === epic.key )
    const sprintIssues = getSprintIssues(epicIssues, sprint);
    const sprintInfo = getSprintInfo(sprintIssues, sprint, sprint.endDate);

    return sprintInfo;
  });

  const round1decimal = (n: number) => Math.round(n * 10) / 10;

  const epicStats = issuesEpic.map(issues => {
    const points = issues.reduce((sum, issue) => sum + issue.points, 0);
    const donePoints = issues.reduce((sum, issue) => sum + issue.donePoints, 0);
    const pct = points > 0 ? round1decimal(donePoints / points * 100) : 0;

    return { pct, points, donePoints };
  });

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Typography className={classes.goalTitle}>
          {epic.name}
        </Typography>

        <EpicStat epicStats={epicStats[0]} />

        { sprintsInfo.map((sprintInfo, index) => (
          <div key={index} className={classes.sprintContainer}>
            <SprintEpicSummary sprintInfo={sprintInfo} />
            <EpicStat epicStats={epicStats[index + 1]} />
          </div>
        ))}
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.goalTitle} />
        <div className={classes.ticketsContainer}>
          { issuesEpic[0].map((issue, index) => (
            <IssueComponent
              key={index}
              fallbackLabel={issue.label}
              issue={issue.issue}
              deleted={issue.deleted}
              statusDate={cycleStartDate}
              pointsDate={cycleStartDate}
            />
          ))}
        </div>

        { sprintsInfo.map((sprintInfo, index) => (
          <div key={index} className={classes.sprintContainer}>
            <SprintIssues sprintInfo={sprintInfo} />
            <div className={classes.ticketsContainer}>
              { issuesEpic[index + 1].map((issue, index2) => (
                <IssueComponent
                  key={index2}
                  fallbackLabel={issue.label}
                  issue={issue.issue}
                  deleted={issue.deleted}
                  statusDate={sprints[index].endDate}
                  pointsDate={cycleStartDate}
                  doneForEpic={true}
                />
              ))}
            </div>
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(styles)(EpicRow);
