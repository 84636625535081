/* eslint-disable import/no-anonymous-default-export */
export default {
  cycles: [
    { key: '23-C1', name: '2023-C1', startDate: '2023-01-02', endDate: '2023-02-10' },
    { key: '23-C2', name: '2023-C2', startDate: '2023-02-20', endDate: '2023-03-21' },
    { key: '23-C3', name: '2023-C3', startDate: '2023-04-10', endDate: '2023-05-19' },
    { key: '23-C4', name: '2023-C4', startDate: '2023-05-29', endDate: '2023-07-07' },
    { key: '23-C5', name: '2023-C5', startDate: '2023-07-17', endDate: '2023-08-11' },
    { key: '23-C6', name: '2023-C6', startDate: '2023-08-21', endDate: '2023-09-29' },
    { key: '23-C7', name: '2023-C7', startDate: '2023-10-09', endDate: '2023-11-17' },
    { key: '23-C8', name: '2023-C8', startDate: '2023-11-27', endDate: '2023-12-22' },
    { key: '24-C1', name: '2024-C1', startDate: '2024-01-02', endDate: '2024-02-09' },
  ],

  boards: [{
    id: 138,
    key: 'MSG',
    name: 'Visitor Experience',
    doneStatus: ['On SD', 'On all platforms', 'Closed', 'Won\'t do'],
    bugsDoneStatus: ['Merged', 'On SD', 'On all platforms', 'Closed', 'Won\'t do'],
    epicDoneStatus: ['On all platforms', 'Closed', 'Won\'t do']
  }, {
    id: 159,
    key: 'TPC',
    name: '3rd-Party Channels',
    doneStatus: ['On SD', 'On all platforms', 'Closed', 'Won\'t do'],
    bugsDoneStatus: ['Merged', 'On SD', 'On all platforms', 'Closed', 'Won\'t do'],
    epicDoneStatus: ['On all platforms', 'Closed', 'Won\'t do']
  }, {
    id: 155,
    key: 'MS',
    name: 'Management Services',
    doneStatus: ['Ready to release', 'Awaiting for HA', 'On all platforms', 'Released on mobile', 'Closed'],
    bugsDoneStatus: ['Merged', 'On SD', 'On all platforms', 'Closed'],
    epicDoneStatus: ['On all platforms', 'Closed', 'Won\'t do']
  }, {
    id: 79,
    key: 'INT',
    name: 'Orchestration Solution',
    doneStatus: ['Done', 'Closed', 'On all platforms', 'Resolved', 'On SD', 'Won\'t do', 'Merged', 'Quality approved on SD'],
    bugsDoneStatus: ['Merged', 'On SD', 'On all platforms', 'Closed'],
    epicDoneStatus: ['On all platforms', 'Closed', 'Won\'t do']
  }, {
    id: 154,
    key: 'BOT',
    name: 'AI/Bot',
    doneStatus: ['Won\'t do', 'On SD', 'On all platforms', 'Closed'],
    bugsDoneStatus: ['Merged', 'On SD', 'On all platforms', 'Closed'],
    epicDoneStatus: ['On all platforms', 'Closed', 'Won\'t do']
  }, {
    id: 114,
    key: 'PLAT',
    name: 'Platform',
    doneStatus: ['Merged', 'On SD', 'On all platforms', 'Closed'],
    bugsDoneStatus: ['Merged', 'On SD', 'On all platforms', 'Closed']
  }, {
    id: 132,
    key: 'OGDK',
    name: 'Desk',
    doneStatus: ['Développement terminé', 'Com. interne terminée', 'Com. SD terminée', 'Merge', 'On SD', 'Com. HA terminée', 'On all platforms', 'Closed'],
    bugsDoneStatus: ['Développement terminé', 'Com. interne terminée', 'Com. SD terminée', 'Merge', 'On SD', 'Com. HA terminée', 'On all platforms', 'Closed']
  }, {
    id: 93,
    key: 'SYSTEM',
    name: 'System',
    doneStatus: ['Done'],
    bugsDoneStatus: ['Merged', 'On SD', 'On all platforms', 'Closed']
  }],

  statusMapping: {
    1: "To Do",
    3: "In Progress",
    4: "Reopened",
    5: "Resolved",
    6: "Closed",
    10000: "To Do",
    10001: "Done",
    10002: "Backlog",
    10003: "Selected for Development",
    10004: "In QA",
    10006: "Qualified",
    10008: "Waiting for Estimation",
    10014: "In Quality",
    10015: "Quality approved",
    10016: "Selected-old",
    10017: "Selected",
    10116: "In code review",
    10216: "Todo soon",
    10316: "On Hold",
    10416: "QA",
    10516: "Ready to release",
    10716: "Quality Rejected",
    10816: "Released on Preprod",
    10817: "Awaiting for HA",
    10818: "On all platforms",
    11116: "Released on mobile",
    11300: "On SD",
    11400: "Quality approved on Ikoula",
    11500: "Merge ready",
    11604: "PR Review",
    11606: "Delivered",
    11607: "Quality Review",
    11630: "Brief",
    11631: "Find solutions",
    11632: "Design & Prototype",
    11633: "Build",
    11634: "Usage analysis",
    11640: "Won't do",
    11641: "Blocked",
    11668: "Développement terminé",
    11671: "Com. SD terminée",
    11672: "Com. interne terminée",
    11681: "Beta",
    11682: "RELEASED",
    11683: "Specifing",
    11684: "Done Estimated",
    11685: "Waiting for review",
    11686: "Ready to build",
    11687: "QA Preprod",
    11688: "Work in progress",
    11689: "Merged",
    11690: "AWAITING FOR SD",
    11691: "Com. HA terminée",
    11695: "In CR & QA"
  },

  statuses: {
    'To Do': {
      '1': 'To Do',
      '4': 'Reopened',
      '10002': 'Backlog',
      '10003': 'Selected for Development',
      '10000': 'To Do',
      '10006': 'Qualified',
      '10008': 'Waiting for Estimation',
      '10016': 'Selected-old',
      '10017': 'Selected',
      '10216': 'Todo soon',
      '11630': 'Brief',
      '11631': 'Find solutions',
      '11632': 'Design & Prototype',
      '11633': 'Build',
      '11634': 'Usage analysis',
      '11641': 'Blocked',
      '11683': 'Specifing',
      '11686': 'Ready to build',
    },
    'In progress': {
      '3': 'In Progress',
      '10716': 'Quality Rejected',
      '11688': 'Work in progress',
    },
    'Code review': {
      '11685': 'Waiting for review',
      '10116': 'In code review',
      '11604': 'PR Review',
      '11695': 'In CR & QA'
    },
    'QA review': {
      '10004': 'In QA',
      '10014': 'In Quality',
      '10416': 'QA',
      '11687': 'QA Preprod',
      '11607': 'Quality Review',
    },
    'Ready to release': {
      '5': 'Resolved',
      '10015': 'Quality approved',
      '11500': 'Merge ready',
      '10316': 'On Hold',
      '11689': 'Merged',
      '11668': 'Développement terminé',
      '10001': 'Done',
      '10516': 'Ready to release',
      '11690': 'AWAITING FOR SD',
      '10816': 'Released on Preprod',
      '11606': 'Delivered',
      '11116': 'Released on mobile',
      '11300': 'On SD',
      '11671': 'Com. SD terminée',
      '11400': 'Quality approved on Ikoula',
      '10817': 'Awaiting for HA',
      '10818': 'On all platforms',
      '11691': 'Com. HA terminée',
      '11672': 'Com. interne terminée',
      '11682': 'RELEASED',
      '11684': 'Done Estimated',
      '11681': 'Beta',
      '11640': 'Won\'t do',
      '6': 'Closed',
    }
  }
};
