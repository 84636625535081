import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import { Cycle } from '../services/cycle';
import { Board, getEpicKey, getVersions, Issue } from '../services/issue';
import { isPartOfCycle, Sprint } from '../services/sprint';
import Category from './cycle/Category';
import { Epic } from '../services/epic';

const styles = createStyles({
  container: {
    background: '#F5F5F5',
    minHeight: '100vh',
    paddingTop: 20
  },
  accordion: {
    width: '90%',
  },
  noGoalIssuesContainer: {
    width: '50%',
    marginBottom: 30,
  },
  noGoalIssues: {
    width: '100%',
  },
  noGoalIssuesDetails: {
    flexDirection: 'column',
  },
  sprintSummary: {
    textAlign: 'center',
    marginBottom: 20,
  },
  sprintTitle: {
    fontWeight: 'bold',
  },
  sprintDetails: {
    fontSize: '0.9em',
  },
  addGoalButton: {
    marginTop: 30,
  },
  goalNameInput: {
    width: 400,
    marginBottom: 30,
  },
  removedPoints: {
    color: '#AAA',
  },
});

interface Props extends WithStyles<typeof styles> {
  board: Board;
  cycle: Cycle;
  sprints: Sprint[];
  boardIssues: Issue[];
};

function uniqBy<T> (arr: T[], key: keyof T): T[] {
  return Object.values(
    arr.reduce(
      (map, item) => ({
        ...map,
        [`${item[key]}`]: item,
      }),
      {},
    ),
  );
};

function CycleDashboard(props: Props) {

  const { classes, board, cycle, sprints, boardIssues } = props;

  const sortedSprints = useMemo(() => sprints
    .filter(sprint => {
      return sprint.state !== 'future' && isPartOfCycle(sprint, cycle);
    })
    .sort((a, b) => {
      return (a.id < b.id) ? -1 : (a.id > b.id) ? 1 : 0;
    }), [sprints, cycle]);

  const [categories, setCategories] = useState<string[]>([]);

  const cycleUniqueId = `${board.key}-${cycle.key}`;
  const cycleStartDate = moment(cycle.startDate).endOf('day');

  const issuesWithChildren: Record<string, Issue[]> = {};

  const enrichedIssues = boardIssues
    .filter(issue => {
      return moment(issue.updated).isAfter(moment(cycle.startDate).subtract(3, 'month'))
    })
    .map(issue => {
      if (issue.parentKey) {
        if (!issuesWithChildren[issue.parentKey]) {
          issuesWithChildren[issue.parentKey] = [];
        }
        issuesWithChildren[issue.parentKey].push(issue);
      }

      return {
        ...issue,
        epicKey: [
          getEpicKey(issue, sortedSprints.length > 0 ? sortedSprints[0].startDate : cycleStartDate),
          ...sortedSprints.map(sprint => getEpicKey(issue, sprint.endDate))
        ],
        isVersionned: [
          cycle.key < '22-C5' || getVersions(issue, sortedSprints.length > 0 ? sortedSprints[0].startDate : cycleStartDate).map(v => v.name).some(v => v === cycle.key || v === cycle.name),
          ...sortedSprints.map(sprint => cycle.key < '22-C5' || getVersions(issue, sprint.endDate).map(v => v.name).some(v => v === cycle.key || v === cycle.name))
        ]
      }
    })
    .map(issue => {
      return {
        ...issue,
        children: issuesWithChildren[issue.key] || []
      }
    });



  const t = enrichedIssues.filter(i => i.key === 'MSG-5265');

  // console.log('MSG-5265', t[0]);
  if (t.length > 0) {
    //console.log(getVersions(t[0], sortedSprints.length > 0 ? sortedSprints[0].startDate : cycleStartDate));
    //sortedSprints.forEach(sprint => console.log(getVersions(t[0], sprint.endDate)));
    //console.log('---');
  }

  const issuesByVersion = enrichedIssues.filter(issue => getVersions(issue, cycle.endDate).map(v => v.name).some(v => v === cycle.key || v === cycle.name));
  const versionEpics = uniqBy(issuesByVersion.map(issue => issue.epic).filter((epic): epic is Epic => !!epic), 'key');

  useEffect(() => {
    // Detect categories from sprints names (ex: Visitor XP - 22C1-S2 => Visitor XP)
    const cat = Array.from(new Set(sortedSprints.map(sprint => sprint.name.split(' - ')[0])));

    if (cat.length === 0 || cat.length === sortedSprints.length) {
      setCategories([cycle.name]);
    } else {
      setCategories(cat);
    }
  }, [sortedSprints, cycle.name]);

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="center" className={classes.container}>
      {categories.map((category, index) => (
        <Category
          key={index}
          cycleUniqueId={cycleUniqueId}
          cycle={cycle}
          category={category}
          sprints={sortedSprints}
          boardIssues={enrichedIssues}
          epics={versionEpics}
        />
      ))}
    </Grid>
  );
};

export default withStyles(styles)(CycleDashboard);
