import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import EpicRow from './EpicRow';
import OtherRow from './OtherRow';
import ClientBugsRow from './ClientBugsRow';
import SprintSummary from './SprintSummary';
import { Cycle } from '../../services/cycle';
import { Issue } from '../../services/issue';
import { Sprint } from '../../services/sprint';
import { Epic } from '../../services/epic';

const styles = createStyles({
  container: {
    padding: 20,
    width: '90%',
    overflowX: 'auto',
  },
  summaries: {
    display: 'flex',
    alignSelf: 'start',
    marginLeft: 516,
  },
  summary: {
    width: 400,
  },
  spacer: {
    width: 200,
  },
  title: {
    textAlign: 'center',
  }
});

interface Props extends WithStyles<typeof styles> {
  cycleUniqueId: string;
  cycle: Cycle;
  category: string;
  sprints: Sprint[];
  boardIssues: Issue[];
  epics: Epic[];
};

function CategoryComponent(props: Props) {

  const { classes, category, cycleUniqueId, sprints, boardIssues, cycle, epics } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const displayedSprints = category === cycle.name ? sprints : sprints.filter(sprint => sprint.name.startsWith(category));

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" className={classes.container}>
      <h2 className={classes.title}>{category}</h2>

      <div className={classes.summaries}>
        { displayedSprints.map((sprint, index) => (
          <div key={index} className={classes.summary}>
            <SprintSummary
              sprint={sprint}
              boardIssues={boardIssues}
              cycleUniqueId={cycleUniqueId}
              categoryName={category}
            />
            <div className={classes.spacer} />
          </div>
        ))}
      </div>

      { epics.map((epic, index) => (
        <EpicRow
          key={index}
          cycle={cycle}
          epic={epic}
          expanded={expanded === `epic${index}`}
          onChange={handleChange(`epic${index}`)}
          boardIssues={boardIssues}
          sprints={displayedSprints}
        />
      ))}

      <div>
        <br />
        <ClientBugsRow
          expanded={expanded === `clientBugsRow`}
          onChange={handleChange(`clientBugsRow`)}
          boardIssues={boardIssues}
          sprints={displayedSprints}
          epics={epics}
        />
        <OtherRow
          expanded={expanded === `otherRow`}
          onChange={handleChange(`otherRow`)}
          boardIssues={boardIssues}
          sprints={displayedSprints}
          epics={epics}
        />
      </div>
    </Grid>
  );
};

export default withStyles(styles)(CategoryComponent);
