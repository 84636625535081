import moment, { Moment } from "moment";
import { ReactIndexedDB } from 'react-indexed-db';
import axios from 'axios';
import config from '../config';
import { Sprint, getSprintIssues } from "./sprint";
import { IssuesJiraResponse } from '../../api/issues';

const HOST = process.env.REACT_APP_API_HOST || '';
const DBVERSION = 5;

export type Board = {
  id: number;
  key: string;
  name: string;
  bugsDoneStatus: string[];
  doneStatus: string[];
  epicDoneStatus?: string[];
};

type Version = {
  id: string;
  name: string;
};

export type Issue = {
  id: string;
  key: string;
  summary: string;
  priorityName: 'Trivial' | 'Minor' | 'Major' | 'Blocker';
  priority: number;
  assignee: string | null;
  zendeskTickets: number | null;
  created: string;
  updated: string;
  type: {
    id: string;
    name: string;
  };
  statusName: string;
  labels: string[];
  epic: {
    id: number;
    key: string;
    name: string;
    summary: string;
    done: boolean;
  } | null
  points: number | null;
  closedSprints: number[];
  sprint: number;
  changelog: {
    created: string;
    field: string;
    fieldId: string;
    from: string;
    to: string;
    fromString: string;
    toString: string;
  }[];
  versions: Version[];
  parentKey: string | null;
  children: Issue[];
};

export function getStatus(issue: Issue, date: Moment | string) {
  const statusChangelog = issue.changelog.find(change => {
    if (!moment(change.created).isBefore(date)) {
      return false;
    }

    return change.field === 'status';
  });

  return statusChangelog ? statusChangelog.to : '1'; // default to to-do
}

export function getPoints(issue: Issue, date: Moment | string) {
  const estimateChangelog = issue.changelog.find(change => {
    if (!moment(change.created).isBefore(date)) {
      return false;
    }

    return change.fieldId === 'customfield_10004'; // = Story Points
  });

  const childrenPoints: number[] = issue.children.map(child => getPoints(child, date));
  const childrenSum: string = issue.children.length > 0 ? childrenPoints.reduce((sum, points) => sum + points, 0).toString() : '0';

  return parseInt(
    (estimateChangelog
      ? estimateChangelog.toString
      : issue.points ? issue.points.toString() : childrenSum
    ),
    10
  ) || 0;
}

export function getEpicKey(issue: Issue, date: Moment | string) {
  const epicChangelog = issue.changelog.find(change => {
    if (!moment(change.created).isBefore(date)) {
      return false;
    }

    return change.fieldId === 'customfield_10008'; // = Epic Link
  });

  if (moment(issue.created).isAfter(moment(date))) {
    return null;
  }

  return epicChangelog
    ? epicChangelog.toString
    : issue.epic && issue.epic.key ? issue.epic.key : null;
}

export function getVersions(issue: Issue, date: Moment | string) {
  if (moment(issue.created).isAfter(moment(date))) {
    return [];
  }

  const versionChangelog = issue.changelog.filter(change => change.fieldId === 'fixVersions');
  const versionChangelogToDate = versionChangelog.filter(change => moment(change.created).isBefore(date));
  const initialVersions = versionChangelog.reduce<Version[]>((versions, change) => {
    if (change.toString !== null) {
      return versions.filter(v => v.id !== change.to);
    } else if (!change.toString && change.fromString !== null) {
      return [...versions, { name: change.fromString, id: change.from }];
    }

    return versions;
  }, issue.versions);

  if (versionChangelogToDate.length === 0) {
    return initialVersions;
  }

  return versionChangelogToDate.reduceRight<Version[]>((versions, change) => {
    if (change.toString !== null) {
      return [...versions, { name: change.toString, id: change.to }];
    } else if (!change.toString && change.fromString !== null) {
      return versions.filter(v => v.id !== change.from);
    }

    return versions;
  }, initialVersions);
}

export function isBug(issue: Issue) {
  return issue.type.id === '1' || issue.type.id === '10503'; // Bug or Security issue
}

export function isClientBug(issue: Issue) {
  return isBug(issue) && (issue.zendeskTickets || 0) > 0; // Zendesk tickets count
}

export function isDone(issue: Issue, date: Moment | string, doneForEpic: boolean = false) {
  const status = getStatus(issue, date);

  const issuePrefix = issue.key.split('-')[0];
  const board: Board | undefined = config.boards.find(board => board.key === issuePrefix);

  if (!board) {
    return false;
  }

  const statuses = Object.entries(config.statusMapping);

  const doneStatuses = isBug(issue)
    ? board.bugsDoneStatus
    : doneForEpic && board.epicDoneStatus
    ? board.epicDoneStatus
    : board.doneStatus;

  const doneStatusesIds = doneStatuses.map(status => {
    const occ = statuses.find(s => s[1] === status);
    return occ ? occ[0] : undefined;
  }).filter(s => s !== undefined);

  return doneStatusesIds.includes(status);
}

export function isInSprint(issue: Issue, sprint: Sprint, date: Moment | string) {
  const sprintIssue = getSprintIssues([issue], sprint);

  if (sprintIssue.length === 0) {
    return false;
  }

  const sprintChangelog = issue.changelog.filter(change => {
    return change.field === 'Sprint';
  }).reverse();

  let inSprint = false;

  sprintChangelog.forEach(change => {
    if (moment(change.created).isBefore(date)) {
      if (change.to.split(', ').includes(sprint.id.toString())) {
        inSprint = true;
      }

      if (change.from.split(', ').includes(sprint.id.toString())) {
        inSprint = false;
      }
    }
  });

  return moment(issue.created).isBefore(date) && (sprintChangelog.length === 0 || inSprint);
}

export function isRemovedFromSprint(issue: Issue, sprint: Sprint, date: Moment | string) {
  const changelogs = issue.changelog.filter(change => {
    return moment(change.created).isBefore(date) && moment(change.created).isAfter(sprint.startDate);
  });

  const addToSprintChangelog = changelogs.find(change => {
    return change.field === 'Sprint' && change.to === sprint.id.toString();
  });

  const removeFromSprintChangelog = changelogs.find(change => {
    return change.field === 'Sprint' && change.from === sprint.id.toString();
  });

  const result = removeFromSprintChangelog && (
    (!addToSprintChangelog) ||
    (
      (addToSprintChangelog &&
      moment(addToSprintChangelog.created).isBefore(moment(removeFromSprintChangelog.created))) === true
    )
  );

  return result;
}

// Issues

export function filterIssues(issues: Issue[], issueKeys: string[]) {
  return issueKeys
    .map(issueKey => issues.find(issue => issue.key === issueKey))
    .filter((issue): issue is Issue => issue !== undefined);
}

type IssuesResponse = {
  issues: Issue[];
  expand: string;
  isLast: boolean;
  maxResults: number;
  total: number;
};

const priorityMapping = ['Trivial', 'Minor', 'Major', 'Blocker'];

function formatIssues(issues: IssuesJiraResponse): IssuesResponse {
  return {...issues, issues: issues.issues.map(issue => {
    return {
      id: issue.id.toString(),
      key: issue.key,
      summary: issue.fields.summary,
      priorityName: issue.fields.priority.name as 'Trivial' | 'Minor' | 'Major' | 'Blocker',
      priority: priorityMapping.indexOf(issue.fields.priority.name),
      assignee: issue.fields.assignee?.displayName || '',
      zendeskTickets: parseInt(issue.fields.customfield_12901, 10),
      updated: issue.fields.updated,
      created: issue.fields.created,
      type: {
        id: issue.fields.issuetype.id,
        name: issue.fields.issuetype.name,
      },
      statusName: issue.fields.status.name,
      labels: issue.fields.labels,
      epicName: issue.fields.epic?.name,
      epic: issue.fields.epic,
      points: parseInt(issue.fields.customfield_10004 || '0', 10),
      closedSprints: issue.fields.closedSprints?.map(sprint => parseInt(sprint.id, 10)) || [],
      sprint: parseInt(issue.fields.sprint?.id || '', 10),
      changelog: issue.changelog.histories.flatMap(change => {
        return change.items.filter(item => {
          return ['status', 'customfield_10004', 'customfield_10007', 'customfield_10008', 'fixVersions'].includes(item.fieldId);
        }).map(item => ({
          field: item.field,
          fieldId: item.fieldId,
          from: item.from?.toString() || '',
          to: item.to?.toString() || '',
          fromString: item.fromString,
          toString: item.toString,
          created: change.created,
        }));
      }),
      versions: issue.fields.fixVersions.map(version => ({name: version.name, id: version.id})),
      parentKey: issue.fields.issuetype.subtask && issue.fields.parent ? issue.fields.parent.key : null,
      children: [],
  };
  })};
}

async function getIssuesPaginated(boardId: number, offset = 0, jqlQuery: string | null): Promise<IssuesJiraResponse> {
  const jql = jqlQuery || `updated >= 2021-09-01 ORDER BY updated DESC`;

  try {
    const issues = await axios.get(`${HOST}/api/issues`, {
      params: {
        boardId,
        startAt: offset,
        jql
      },
      headers: {
        authorization: localStorage.getItem('access_token') || 'a'
      }
    });

    return issues.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // redirect
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('route', window.location.pathname);
      window.location.replace(`${window.location.origin}/login?${urlParams.toString()}`);
    }

    return {
      issues: [],
      expand: '',
      isLast: true,
      maxResults: 0,
      total: 0,
    };
  }
}

async function getBoardIssues(boardId: number, lastFetchedDate: string | null) {
  const boardIssues: Issue[] = [];
  const jql = lastFetchedDate ? `updated>"${lastFetchedDate}" ORDER BY updated DESC` : null;

  const issuesFirstPage = await getIssuesPaginated(boardId, 0, jql);
  boardIssues.push(...formatIssues(issuesFirstPage).issues);

  let results: IssuesJiraResponse[] = [];

  if (issuesFirstPage.total > issuesFirstPage.maxResults) {
    const parallelRequests = Math.ceil((issuesFirstPage.total - issuesFirstPage.maxResults) / issuesFirstPage.maxResults);

    const requests = Array(parallelRequests).fill(null).map((_, offset) => {
      return getIssuesPaginated(boardId, (offset + 1) * issuesFirstPage.maxResults, jql);
    });

    results = await Promise.all(requests);
  }

  results.forEach(page => {
    if (page) {
      boardIssues.push(...formatIssues(page).issues);
    } else {
      console.log('page null', page);
    }
  });

  return boardIssues;
}

export async function getBoardIssuesWithCache(boardId: number, cacheOnly = false) {
  const db = new ReactIndexedDB('jira', DBVERSION);
  const issuesTable = `board${boardId}-issues-${DBVERSION}`;
  const lastDateTable = `board${boardId}-${DBVERSION}`;

  await db.openDatabase(DBVERSION, (evt: any) => {
    const request = evt.currentTarget;
    config.boards.forEach(board => {
      request.result.createObjectStore(`board${board.id}-${DBVERSION}`);
      request.result.createObjectStore(`board${board.id}-issues-${DBVERSION}`);
    });
  });

  if (cacheOnly) {
    const boardIssues: { issue: string }[] = await db.getAll(issuesTable);
    return boardIssues.map(boardIssue => JSON.parse(boardIssue.issue) as Issue);
  }

  const savedBoardInfo: { updated: string }[] = await db.getAll(lastDateTable);
  const lastFetchedDate = savedBoardInfo[0]?.updated || null;

  console.log({lastFetchedDate});

  // Requêter les pages tant que la date updated de la dernière issue >= lastFetchedDate
  const updatedIssues = await getBoardIssues(boardId, lastFetchedDate);

  // Mettre à jour la bdd avec ces issues
  for (const issue of updatedIssues) {
    await db.update(issuesTable, {
      issue: JSON.stringify(issue),
    }, issue.key);
  }

  // GetAll la bdd
  const boardIssues: { issue: string }[] = await db.getAll(issuesTable);

  // MAJ de la date de fetch
  await db.update(lastDateTable, {
    updated: moment().format('YYYY-MM-DD 00:00'),
  }, boardId);

  return boardIssues.map(boardIssue => JSON.parse(boardIssue.issue) as Issue);
}
