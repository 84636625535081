import axios from 'axios';
import moment from 'moment';
import { Issue, isDone, isInSprint, getPoints, isRemovedFromSprint, isBug, isClientBug } from './issue';
import { Moment } from 'moment';
import { Cycle } from './cycle';

const HOST = process.env.REACT_APP_API_HOST || '';

export type Sprint = {
  id: number;
  self: string; // ex: 'https://iadvize.atlassian.net/rest/agile/1.0/sprint/1420'
  state: string; // closed | ...
  name: string;
  startDate: string;
  endDate: string;
  completeDate: string;
  originBoardId: number;
  goal: string;
};

type StateInfo = {
  issues: Issue[];
  points: number;
  bugs: Issue[];
  clientBugs: Issue[];
};

export type SprintInfo = {
  sprint: Sprint;
  initial: {
    done: StateInfo;
    all: StateInfo;
    engagement: number;
  };
  initialRemoved: StateInfo;
  added: {
    done: StateInfo;
    all: StateInfo;
  };
  final: {
    done: StateInfo;
    all: StateInfo;
    engagement: number;
  }
};

export function isPartOfCycle(sprint: Sprint, cycle: Cycle) {
  if (!sprint.startDate && moment().format() < moment(cycle.endDate).format()) {
    return true;
  }

  return moment(sprint.startDate) >= moment(cycle.startDate) &&
    moment(sprint.startDate) < moment(cycle.endDate);
}

export function filterSprints(sprints: Sprint[], sprintIds: (string | number)[]) {
  return sprintIds
    .map(sprintId => sprints.find(s => s.id.toString() === sprintId.toString()))
    .filter((s): s is Sprint => s !== undefined);
}

export async function getSprintProperty(sprintId: number, property: string) {
  try {
    const propertyValue = await axios.get(`${HOST}/api/sprintProperty`, {
        params: {
          sprintId,
          property
        },
        headers: {
          authorization: localStorage.getItem('access_token') || 'a'
        }
    });

    return propertyValue.data;

  } catch (error) {
    if (error.response && error.response.status === 401) {
      // redirect
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('route', window.location.pathname);
      window.location.replace(`${window.location.origin}/login?${urlParams.toString()}`);
    }

    return null;
  }
}

export async function setSprintProperty(sprintId: number, property: string, propertyValue: Object) {
  try {
    await axios({
        url: `${HOST}/api/sprintProperty`,
        method: 'put',
        params: {
          sprintId: sprintId,
          property
        },
        data: propertyValue,
        headers: {
          authorization: localStorage.getItem('access_token') || 'a'
        }
    });

  } catch (error) {
    if (error.response && error.response.status === 401) {
      // redirect
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('route', window.location.pathname);
      window.location.replace(`${window.location.origin}/login?${urlParams.toString()}`);
    }
  }
}

export function getSprintIssues(boardIssues: Issue[], sprint: Sprint) {
  return boardIssues.filter(issue => {
    const issueSprintIds = [];

    if (issue.closedSprints && issue.closedSprints.length > 0) {
      issue.closedSprints.forEach(sprintId => {
        issueSprintIds.push(sprintId);
      });
    }

    if (issue.sprint) {
      issueSprintIds.push(issue.sprint);
    }

    // Tickets retirés en cours de sprint
    issue.changelog.forEach(change => {
      if (
        change.field === 'Sprint' &&
        change.from && change.from.split(', ').includes(sprint.id.toString()) &&
        moment(change.created).format() > moment(sprint.startDate).format()
      ) {
        issueSprintIds.push(sprint.id);
      }
    });

    return issueSprintIds.includes(sprint.id) && !isDone(issue, sprint.startDate);
  });
}

export function getSprintInfo(issues: Issue[], sprint: Sprint, currentDate: Moment | string) {

  const stateInfo: StateInfo = {
    issues: [],
    points: 0,
    bugs: [],
    clientBugs: [],
  };

  const info: SprintInfo = {
    sprint,
    initial: {
      done: {...stateInfo},
      all: {...stateInfo},
      engagement: 0,
    },
    initialRemoved: {...stateInfo},
    added: {
      done: {...stateInfo},
      all: {...stateInfo},
    },
    final: {
      done: {...stateInfo},
      all: {...stateInfo},
      engagement: 0,
    }
  };

  const startDate = moment(sprint.startDate);
  const endDate = moment(sprint.endDate);

  currentDate = currentDate || endDate;

  issues.forEach(issue => {
    if (isInSprint(issue, sprint, currentDate)) {
      const isInitial = isInSprint(issue, sprint, startDate);
      const isRemoved = isRemovedFromSprint(issue, sprint, currentDate);

      const issuePoints = getPoints(issue, currentDate);

      const updateState = (state: StateInfo) => {
        state.issues = [...state.issues, issue];
        state.points += issuePoints;
        state.bugs = isBug(issue) ? [...state.bugs, issue] : state.bugs;
        state.clientBugs = isClientBug(issue) ? [...state.clientBugs, issue] : state.clientBugs;
      }

      !isRemoved && updateState(info.final.all);
      isInitial && updateState(info.initial.all);

      isInitial && isRemoved && updateState(info.initialRemoved);
      !isInitial && !isRemoved && updateState(info.added.all);

      if (isDone(issue, currentDate)) {
        !isRemoved && updateState(info.final.done);
        isInitial && updateState(info.initial.done);
        !isInitial && !isRemoved && updateState(info.added.done);
      }
    }
  });

  // Engagement
  const round1decimal = (n: number) => Math.round(n * 10) / 10;

  info.initial.engagement = round1decimal((info.initial.done.points) / (info.initial.all.points - info.initialRemoved.points) * 100) || 0;
  info.final.engagement = round1decimal(info.final.done.points / info.initial.all.points * 100) || 0;

  return info;
}

export async function getSprints(boardId: number): Promise<Sprint[]> {
  const urlParams = new URLSearchParams(window.location.search);

  try {
    const sprints = await axios.get<Sprint[]>(`${HOST}/api/sprints`, {
      params: {
        boardId
      },
      headers: {
        authorization: localStorage.getItem('access_token') || 'a'
      }
    });

    const filteredSprints = sprints.data.filter(sprint => {
      return sprint.originBoardId.toString() === boardId.toString();
    }).map(sprint => {
      return {
        ...sprint,
        endDate: sprint.completeDate ? sprint.completeDate : sprint.endDate,
      };
    });

    return filteredSprints;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // redirect
      urlParams.set('route', window.location.pathname);
      window.location.replace(`${window.location.origin}/login?${urlParams.toString()}`);
    }

    return [];
  }
}
