import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Sprint, getSprintInfo, getSprintIssues } from '../../services/sprint';
import { Issue } from '../../services/issue';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';

const styles = createStyles({
  container: {
    width: 200,
  },
  sprintSummary: {
    textAlign: 'center',
    marginBottom: 20,
  },
  sprintTitle: {
    fontWeight: 'bold',
  },
  sprintDates: {
    fontSize: '0.8em',
    fontWeight: 'normal',
  },
  sprintDetails: {
    fontSize: '0.9em',
  },
  removedPoints: {
    color: '#AAA',
  },
});

interface Props extends WithStyles<typeof styles> {
  sprint: Sprint;
  boardIssues: Issue[];
  cycleUniqueId: string;
  categoryName: string;
};

function SprintSummary(props: Props) {

  const { classes, sprint, boardIssues } = props;

  const sprintIssues = getSprintIssues(boardIssues, sprint);
  const sprintInfo = getSprintInfo(sprintIssues, sprint, sprint.endDate);

  const sprintGoals = sprint.goal ? sprint.goal.split('\n') : [];

  return (
    <div className={classes.container}>
      <div className={classes.sprintSummary}>
        <Tooltip arrow title={
            <div>
              <strong>Objectifs du sprint :</strong><br />
              {sprintGoals.map((goal, index) => (<span key={index}>{goal}<br /></span>))}
              {sprintGoals.length === 0 && '<Non définis>'}
            </div>
          }
          interactive placement="right"
        >
          <div className={classes.sprintTitle}>
            {sprint.name}
            <br />
            <span className={classes.sprintDates}>
              {moment(sprint.startDate).format('DD/MM/YY')} - {moment(sprint.endDate).format('DD/MM/YY')}
            </span>
          </div>
        </Tooltip>
        <span className={classes.sprintDetails}>
          <Tooltip title="Points prévus / Points ajoutés ou retirés / Points done" arrow>
            <span>
              {sprintInfo.initial.all.points}pts
              { sprintInfo.added.all.issues.length > 0 || sprintInfo.initialRemoved.issues.length > 0 ? (
                <span>&nbsp;/ <span className={classes.removedPoints}>
                  {sprintInfo.initialRemoved.issues.length > 0 ? `-${sprintInfo.initialRemoved.points}pts` : ''}
                  {sprintInfo.initialRemoved.issues.length > 0 && sprintInfo.added.all.issues.length > 0 ? ',' : ''}
                  {sprintInfo.added.all.issues.length > 0 ? `+${sprintInfo.added.all.points}pts` : ''}
                  </span>
                </span>
              ) : null }
              &nbsp;/ <strong>{sprintInfo.final.done.points}pts</strong>
            </span>
          </Tooltip>
          <br />
          { !isNaN(sprintInfo.initial.engagement) && (
            <Tooltip title="Engagement du sprint (points prévus done/points prévus)" arrow>
              <span>{sprintInfo.initial.engagement}% ({sprintInfo.initial.done.points}/{sprintInfo.initial.all.points}pts)</span>
            </Tooltip>
          )}
        </span>
      </div>
    </div>
  );
};

export default withStyles(styles)(SprintSummary);
