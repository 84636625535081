import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import SprintsPage from './SprintsPage';
import LoginPage from './LoginPage';

export default function App() {

  return (
    <GoogleOAuthProvider clientId="5316226138-lj6em0tmov2mbegujfmgq93q1tq8a2ov.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/:board/:cycle" element={<SprintsPage />} />
          <Route path="/:board" element={<SprintsPage />} />
          <Route path="/" element={<SprintsPage />} />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};
