import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { SprintInfo } from '../../services/sprint';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const styles = createStyles({
  container: {
    width: 200,
    textAlign: 'center',
    fontSize: '0.8em',
    flexShrink: 0,
  },
  removedPoints: {
    color: '#AAA',
  },
  donePoints: {
    color: '#78A65A',
  },
});

interface Props extends WithStyles<typeof styles> {
  sprintInfo: SprintInfo;
};

function SprintEpicSummary(props: Props) {

  const { classes, sprintInfo } = props;

  if (sprintInfo.final.all.issues.length === 0) {
    return <div className={classes.container} />;
  }

  const isAddedOrRemovedPoints = sprintInfo.added.all.issues.length > 0 || sprintInfo.initialRemoved.issues.length > 0;
  const isAllInitialPointsDone = sprintInfo.initial.all.points === sprintInfo.initial.done.points;

  return (
    <Tooltip title={`Points prévus ${isAddedOrRemovedPoints ? '/ Points ajoutés ou retirés' : ''} / Points done`} arrow>
      <Typography className={classes.container}>
        <span className={`${isAllInitialPointsDone ? classes.donePoints : ''}`}>{sprintInfo.initial.all.points}pts</span>
        { isAddedOrRemovedPoints ? (
          <span>&nbsp;/ <span className={classes.removedPoints}>
            {sprintInfo.initialRemoved.issues.length > 0 ? `-${sprintInfo.initialRemoved.points}pts` : ''}
            {sprintInfo.initialRemoved.issues.length > 0 && sprintInfo.added.all.issues.length > 0 ? ',' : ''}
            {sprintInfo.added.all.issues.length > 0 ? `+${sprintInfo.added.all.points}pts` : ''}
            </span>
          </span>
        ) : null }
        &nbsp;/ <strong>{sprintInfo.final.done.points}pts</strong>
      </Typography>
    </Tooltip>
  );
}

export default withStyles(styles)(SprintEpicSummary);
