import React, { useState, useCallback, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import { getSprints, Sprint } from './services/sprint';

import config from './config';
import moment from 'moment';
import CycleDashboard from './components/CycleDashboard';
import { useParams } from 'react-router-dom';
import { Board, getBoardIssuesWithCache, Issue } from './services/issue';
import { Cycle } from './services/cycle';

const styles = createStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row'
  },
  mainMenu: {
    display: 'flex',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1
  },
  select: {
    marginLeft: 20,
    marginRight: 20,
  },
  loader: {
    textAlign: 'center',
    marginTop: 100
  },
});

const urlParams = new URLSearchParams(window.location.search);
const urlLabel = urlParams.get('label');

const descCycles: Cycle[] = config.cycles.sort((a, b) => a.startDate > b.startDate ? -1 : 1);

function getInitialBoard(boardKey?: string): Board {
  if (boardKey) {
    const currentBoard = config.boards.find(board => board.key === boardKey);

    if (currentBoard) {
      return currentBoard;
    }
  }

  return config.boards[0];
}

function getInitialCycle(cycleParam?: string): Cycle {
  if (cycleParam) {
    const currentCycle = config.cycles.find(cycle => cycle.key === cycleParam || cycle.name === cycleParam);

    if (currentCycle) {
      return currentCycle;
    }
  }

  return config.cycles.find(cycle => cycle.startDate < moment().format()) || config.cycles[0];
}

type Props = {} & WithStyles<typeof styles>;

const SprintsPage = ({ classes }: Props) => {

  const params = useParams();
  const [selectedBoard, setSelectedBoard] = useState(getInitialBoard(params.board));
  const [selectedCycle, setSelectedCycle] = useState(getInitialCycle(params.cycle));
  const [selectedLabel, setSelectedLabel] = useState(urlLabel ? urlLabel : 'all');

  const [boardSprints, setBoardSprints] = useState<Sprint[]>([]);

  const [boardIssuesFetched, setBoardIssuesFetched] = useState(false);
  const [boardIssues, setBoardIssues] = useState<Issue[]>([]);
  const [boardIssuesByLabel, setBoardIssuesByLabel] = useState<Issue[]>([]);

  useEffect(() => {
    const loadBoard = async () => {
      const sprints = await getSprints(selectedBoard.id);
      setBoardSprints(sprints);

      const boardIssuesOnlyCache = await getBoardIssuesWithCache(selectedBoard.id, true);

      setBoardIssuesFetched(boardIssuesOnlyCache.length > 0);
      setBoardIssues(boardIssuesOnlyCache);

      const boardIssuesFull = await getBoardIssuesWithCache(selectedBoard.id);

      setBoardIssuesFetched(true);
      setBoardIssues(boardIssuesFull);
    };

    loadBoard();
  }, [selectedBoard]);

  useEffect(() => {
    setBoardIssuesByLabel(selectedLabel === 'all' ? boardIssues : boardIssues.filter(issue => {
      return issue.labels.includes(selectedLabel);
    }))
  }, [boardIssues, selectedLabel]);

  const handleBoardChange = useCallback((value: number) => {
    const newBoard: Board | undefined = config.boards.find(board => board.id === value);

    if (!newBoard) return;

    setBoardIssuesFetched(false);
    setBoardIssues([]);
    setSelectedBoard(newBoard);
  }, []);

  const handleCycleChange = useCallback((value: string) => {
    const newCycle: Cycle | undefined = config.cycles.find(cycle => cycle.name === value);

    if (!newCycle) return;

    setSelectedCycle(newCycle);
  }, []);

  const handleLabelChange = useCallback((value: string) => {
    setSelectedLabel(value);
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Select
            value={(selectedBoard && selectedBoard.id) || ''}
            onChange={(e) => handleBoardChange(e.target.value as number)}
            className={classes.select}
          >
            {
              config.boards.map(board => (
                <MenuItem value={board.id} key={board.name}>
                  <Typography variant="h6" color="inherit">
                    {board.name}
                  </Typography>
                </MenuItem>
              ))
            }
          </Select>
          <Select
            value={(selectedCycle && selectedCycle.name) || ''}
            onChange={(e) => handleCycleChange(e.target.value as string)}
            className={classes.select}
          >
            {
              descCycles.map(cycle => (
                <MenuItem value={cycle.name} key={cycle.name}>
                  <Typography variant="h6" color="inherit">
                    {cycle.name}
                  </Typography>
                </MenuItem>
              ))
            }
          </Select>
          <Select
            value={selectedLabel || 'all'}
            onChange={(e) => handleLabelChange(e.target.value as string)}
            className={classes.select}
          >
            <MenuItem value={'all'}>
              <Typography variant="h6" color="inherit">
                Tous labels
              </Typography>
            </MenuItem>
            <MenuItem value={'BACK'}>
              <Typography variant="h6" color="inherit">
                BACK
              </Typography>
            </MenuItem>
            <MenuItem value={'FRONT'}>
              <Typography variant="h6" color="inherit">
                FRONT
              </Typography>
            </MenuItem>
            <MenuItem value={'MOBILE'}>
              <Typography variant="h6" color="inherit">
                MOBILE
              </Typography>
            </MenuItem>
          </Select>
        </Toolbar>
      </AppBar>

      {boardSprints.length === 0 ? <div className={classes.loader}>No sprint found</div> : null}
      {!boardIssuesFetched ? <div className={classes.loader}>Loading board issues...</div> : null}

      {boardIssuesFetched && boardSprints.length > 1 ?
        <CycleDashboard
          board={selectedBoard}
          cycle={selectedCycle}
          sprints={boardSprints}
          boardIssues={boardIssuesByLabel}
        /> : null
      }
    </div>
  );
};

export default withStyles(styles)(SprintsPage);
