import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { Issue, isClientBug } from '../../services/issue';
import SprintIssues from './SprintIssues';
import SprintEpicSummary from './SprintEpicSummary';
import { Sprint, getSprintIssues, getSprintInfo } from '../../services/sprint';
import { Epic } from '../../services/epic';

const styles = createStyles({
  column: {
    width: 200,
    textAlign: 'center',
    flexShrink: 0,
  },
  goalTitle: {
    width: 300,
    flexShrink: 0,
  },
  goalPoints: {
    color: '#999',
    fontSize: '0.8em',
  },
  removedPoints: {
    color: '#AAA',
  },
  ticketsContainer: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    fontSize: '0.9em',
  },
  sprintContainer: {
    width: 400,
    flexShrink: 0,
    display: 'flex',
  }
});

type EnrichedIssue = Issue & {
  epicKey: string[];
};

interface Props extends WithStyles<typeof styles> {
  expanded: boolean;
  onChange: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  boardIssues: Issue[];
  sprints: Sprint[];
  epics: Epic[];
};

function OtherRow(props: Props) {

  const { classes, expanded, onChange, boardIssues, sprints, epics } = props;

  const epicsKey = epics.map(epic => epic.key);

  const sprintsInfo = sprints.map((sprint, index) => {
    const noEpicIssues = boardIssues.filter(i => !epicsKey.includes((i as EnrichedIssue).epicKey[index + 1]) && !isClientBug(i))
    const sprintIssues = getSprintIssues(noEpicIssues, sprint);
    const sprintInfo = getSprintInfo(sprintIssues, sprint, sprint.endDate);

    return sprintInfo;
  });

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Typography className={classes.goalTitle}>
          Autres tickets
        </Typography>

        <div className={classes.column} />

        { sprintsInfo.map((sprintInfo, index) => (
          <div key={index} className={classes.sprintContainer}>
            <SprintEpicSummary sprintInfo={sprintInfo} />
            <div className={classes.column} />
          </div>
        ))}
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.goalTitle} />
        <div className={classes.column} />

        { sprintsInfo.map((sprintInfo, index) => (
          <div key={index} className={classes.sprintContainer}>
            <SprintIssues sprintInfo={sprintInfo} />
            <div className={classes.column} />
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(styles)(OtherRow);
