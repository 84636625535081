import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { SprintInfo } from '../../services/sprint';
import IssueComponent from './Issue';

const styles = createStyles({
  sprintTicketsListContainer: {
    width: 200,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  ticketsList: {
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: '0.9em',
    marginBottom: 30,
  },
  ticketPoints: {
    color: '#AAA',
    fontSize: '0.8em',
  },
});

interface Props extends WithStyles<typeof styles> {
  sprintInfo: SprintInfo;
};

function SprintIssues(props: Props) {

  const { classes, sprintInfo } = props;

  const removedIssuesKey = sprintInfo.initialRemoved.issues.map(issue => issue.key);

  return (
    <div className={classes.sprintTicketsListContainer}>
      <div className={classes.ticketsList}>
        { sprintInfo.initial.all.issues.length > 0 && (<strong>Prévus ({sprintInfo.initial.all.points}pts)</strong>)}
        { sprintInfo.initial.all.issues.map((issue, index) => (
          <IssueComponent
            key={index}
            fallbackLabel={''}
            issue={issue}
            statusDate={sprintInfo.sprint.endDate}
            pointsDate={sprintInfo.sprint.endDate}
            deleted={removedIssuesKey.includes(issue.key)}
          />
        ))}
      </div>
      <div className={classes.ticketsList}>
        { sprintInfo.added.all.issues.length > 0 && (<strong>Ajouts ({sprintInfo.added.all.points}pts)</strong>)}
        { sprintInfo.added.all.issues.map((issue, index) => (
          <IssueComponent
            key={index}
            fallbackLabel={''}
            issue={issue}
            statusDate={sprintInfo.sprint.endDate}
            pointsDate={sprintInfo.sprint.endDate}
          />
        ))}
      </div>
    </div>
  );
}

export default withStyles(styles)(SprintIssues);
