import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import axios from 'axios';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const styles = createStyles({
  root: {
    flexGrow: 1,
  },
  loader: {
    textAlign: 'center',
    marginTop: 100
  },
});

const HOST = process.env.REACT_APP_API_HOST;

type Props = {} & WithStyles<typeof styles>;

const LoginPage = ({ classes }: Props) => {

  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      // Call vers POST /api/login {googleToken: X}
      const jwt = await axios.post(`${HOST}/api/login`, {
        data: {
          googleToken: access_token,
        }
      });

      if (jwt && jwt.data && jwt.data.token) {
        localStorage.setItem('access_token', jwt.data.token);
        console.log('Login successful');

        const urlParams = new URLSearchParams(window.location.search);

        const route = urlParams.get('route');
        urlParams.delete('route');
        urlParams.delete('org');
        urlParams.delete('username');
        urlParams.delete('token');

        navigate(`${route || '/'}?${urlParams.toString()}`);
      } else {
        console.log('Login failed');
        console.error(jwt && jwt.data);
      }
    },
    onError: (errorResponse) => {
      console.log('Login faield');
      console.log(errorResponse);
    }
  });

  return (
    <div className={classes.root}>
      <div className={classes.loader}>
        <Button onClick={() => googleLogin()}>
          Se connecter à Jira-reports
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(LoginPage);
