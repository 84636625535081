import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Issue, isDone, getPoints } from '../../services/issue';
import { Tooltip, Link } from '@material-ui/core';
import { Moment } from 'moment';

const styles = createStyles({
  noIssue: {
    backgroundColor: 'orange',
    width: 'fit-content',
  },
  issue: {
    width: 'fit-content',
  },
  points: {
    color: '#AAA',
    fontSize: '0.8em',
  },
  deleted: {
    color: 'red',
    textDecoration: 'line-through'
  },
  done: {
    color: '#78A65A',
  },
  updated: {
    color: 'orange'
  }
});

interface Props extends WithStyles<typeof styles> {
  fallbackLabel: string;
  issue: Issue | undefined;
  updated?: boolean;
  deleted?: boolean;
  statusDate: Moment | string;
  pointsDate: Moment | string;
  doneForEpic?: boolean;
};

function IssueComponent(props: Props) {

  const { classes, fallbackLabel, issue, updated, deleted, statusDate, pointsDate, doneForEpic } = props;

  if (!issue) {
    return (
      <div className={classes.noIssue}>{fallbackLabel}</div>
    );
  }

  const done = isDone(issue, statusDate, doneForEpic);
  const points = getPoints(issue, pointsDate);

  const doneChildren = issue.children.map(child => isDone(child, statusDate, doneForEpic));
  const pointsChildren = issue.children.map(child => getPoints(child, pointsDate));

  return (
    <>
      <Tooltip title={`${issue.key} - ${issue.summary.trim()}`} arrow interactive placement="right">
        <div className={classes.issue}>
          <Link
            color="inherit"
            href={`https://iadvize.atlassian.net/browse/${issue.key}`}
            className={`${deleted ? classes.deleted : ''} ${done ? classes.done : ''} ${updated ? classes.updated : ''}`}
          >
            {issue.key}
          </Link>
          &nbsp;
          <span className={classes.points}>({points || '?'}pts)</span>
        </div>
      </Tooltip>
      {issue.children.map((child, i) => (
        <Tooltip key={i} title={`${child.key} - ${child.summary.trim()}`} arrow interactive placement="right">
          <div className={classes.issue}>
          &nbsp;&nbsp;&nbsp;&nbsp;- <Link
              color="inherit"
              href={`https://iadvize.atlassian.net/browse/${child.key}`}
              className={`${deleted ? classes.deleted : ''} ${doneChildren[i] ? classes.done : ''} ${updated ? classes.updated : ''}`}
            >
              {child.key}
            </Link>
            &nbsp;
            <span className={classes.points}>({pointsChildren[i] || '?'}pts)</span>
          </div>
        </Tooltip>
      ))}
    </>
  );
}

export default withStyles(styles)(IssueComponent);
